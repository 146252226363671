import React from 'react';
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper lsdsmw2eiw-editor_css',
        },
        bg: { className: 'bg bg0 lsds03di2h-editor_css' },
        title: {
          className: 'banner1-title lsds26vc8j-editor_css',
          children: (
            <span>
              <span>
                <p>MTTC</p>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner1-content lsds1t8bohi-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                SYDNEY #1 Table Tennis Training Centre<br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        button: {
          className: 'banner1-button lsdxf3qfq8-editor_css',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1 lsdskd2j3qs-editor_css' },
        title: {
          className: 'banner1-title lsdxddeqby-editor_css',
          children:
            'https://i.miji.bid/2024/02/09/036466841cf92f3657e69f74ae5fa232.md.jpeg',
        },
        content: {
          className: 'banner1-content lsdt77x0hqu-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                Focus on adult training, youth training, and
                                hope to cultivate more state team and national
                                team players in Australia<br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        button: {
          className: 'banner1-button lsdxm80dtxo-editor_css',
          children: 'Learn More',
        },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper lse9gdydjhh-editor_css',
  },
  page: { className: 'home-page content0 lse4wodt2jr-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Products and Services for Adults</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lsdukw06fjf-editor_css',
              children:
                'https://ouch-cdn2.icons8.com/Muwq923Sre5Tb5zCQoUzQeZTMjs71GNIPt5KGDIfhoY/rs:fit:368:294/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNDM0/LzkzYTFkNWMzLTIw/YjItNDQ3Mi05ZmNm/LWI0MGMzNzBlMjhh/ZC5zdmc.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Group Lesson for Adults (Chinese Language)</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>
                                    Group size: 4-8 Duration: 2.5 hours Classes
                                    Organised by Skill&nbsp;
                                  </p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lsdunkyct-editor_css',
              children:
                'https://ouch-cdn2.icons8.com/o3X2TeRpBsaZQfe-HBJHX53j50XY7_u3vijIZ8qJP9s/rs:fit:368:245/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMzEx/L2Y3NzViZjU2LWVk/ODUtNDY0NS1hMzVh/LWJkYTcwNTE5ODFk/OC5zdmc.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p> Weekend Social Ping-Pong (includes group teaching)</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      On Saturdays and Sundays,
                                      9:00-11:30AM&nbsp;
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lsdupwfb4ys-editor_css',
              children:
                'https://ouch-cdn2.icons8.com/opSXawAjJjjjSQ_iYpgAuEUiuABXmPqEcDWsRqa7JKs/rs:fit:368:276/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMTMw/LzE4ZmIwYjEzLTg5/YjAtNGQ4Mi1iNjNj/LWE5ZmI4MjgxMmUz/ZC5zdmc.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              Mature and Seniors Social Table Tennis (includes
                              group teaching)
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Group size: 4-8 Monday-Friday 9:00-11:30AM (except
                          public holidays)
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper lse9i84y1pp-editor_css',
  },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Products and Services for&nbsp;Teenagers</p>
            </span>
          </span>
        ),
        className: 'lse9j7an93t-editor_css',
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lsdv9khc14-editor_css',
              children:
                'https://ouch-cdn2.icons8.com/gdJuhA3CaoK1Ae38-m-WRhL3q6CGapNajvXOxZL0Xts/rs:fit:368:245/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvMjgw/LzllMmQ1MzU2LTgx/NWEtNDc3Yi05MmIx/LWI3MTQ4MDRlNzFl/My5zdmc.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Teenagers and Children’s Introductory Class</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <p>
                                                Group size: 4-10 (for groups of
                                                6 or more, there will be two
                                                teachers present) Duration: 2
                                                hours&nbsp;
                                              </p>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lsdvabww877-editor_css',
              children:
                'https://ouch-cdn2.icons8.com/bU1suIY42rybhON9QwFN9YYEWC6toYoXtWXFaA85yXk/rs:fit:368:368/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvNjM4/LzkwYTY4M2YyLTJm/NDAtNDNiNi05M2Ix/LTJjZmU3YjZkN2I0/Yy5zdmc.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p> Teenagers’ Intermediate Class</p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                Group size: 4-10 (for groups of 6 or more, there
                                will be two teachers present) Duration: 2.5
                                hours&nbsp;
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lsdvb0qsdr6-editor_css',
              children:
                'https://ouch-cdn2.icons8.com/u7ANMzvdEJCaX73opUMtJ7nJroDDzbP6kxmWxqVbNgM/rs:fit:368:276/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9zdmcvOTk2/LzdiY2EwZmFhLTcw/N2EtNGU1YS1iNGQ2/LTJlZjUwNWVjNzVh/MC5zdmc.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p> Teenagers’ Advanced Class&nbsp;</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        {' '}
                        Group size: 4-10 (for groups of 6 or more, there will be
                        two teachers present) Duration: 2.5 hours&nbsp;
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper lse9ifqqqrg-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://i.miji.bid/2024/02/09/0d69b8a15689b81cd91ce9c8ca72670c.md.jpeg',
    className: 'lsdvcu2gg1-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>Choose MTTC Table Tennis Club </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <p>
              MTTC Table Tennis Club is a community of passionate table tennis
              enthusiasts who come together to play, learn, and compete in a fun
              and supportive environment. MTTC offers private training, group
              training and social play for all levels of adults and kids. Call
              us to find more discount ! With a focus on skill-building and
              sportsmanship, the club offers a unique opportunity for players of
              all levels to connect and grow their love for the sport.
            </p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Contact00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content10-wrapper lse9v5o2dzq-editor_css',
  },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: {
        children: 'https://maps.app.goo.gl/M9t5677FhVbpmPsRA',
        name: '跳转地址',
      },
      title: { children: 'MTTC Table Tennis Club', name: '弹框标题' },
      content: {
        children: '2/57a Rhodes St, Hillsdale NSW 2036',
        name: '弹框内容',
      },
    },
  },
};
export const Teams10DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper lse9mwpommr-editor_css',
  },
  page: { className: 'home-page teams1 lse9mvkaknk-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Contact Now</p>
            </span>
          </span>
        ),
        className: 'lse9ts0z8fg-editor_css',
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block~lspmuphsbvh',
        className: 'block lsdwfrjupqr-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://img.icons8.com/?size=160&id=hxq5tCJzmU2Z&format=png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>0491679384</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block lsdwfrjupqr-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://img.icons8.com/?size=160&id=brhy7Fn5v6ri&format=png',
            },
            {
              name: 'title',
              className: 'teams1-title lsppf4h61nh-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Mttc Table Tennis Club</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~lspmuk4k1r',
        className: 'block lsdwfrjupqr-editor_css',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://img.icons8.com/?size=160&id=CxvH1Dye4SAh&format=png',
            },
            {
              name: 'title',
              className: 'teams1-title lspn1slesy9-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>2734707284@qq.com</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image lsdwe16wl3p-editor_css',
              children:
                'https://i.miji.bid/2024/02/09/e1baea93ce3a0bc0cded7d2304e661c3.md.jpeg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Miranda</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'https://i.miji.bid/2024/02/09/6890d7c26859b414b6921150c934f80c.md.jpeg',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>MTTC乒乓球俱乐部</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content~lsdw6llfbu',
              className: 'teams1-job',
              children: (
                <span>
                  <span>
                    <p>Number：209215601</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer11DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer1-wrapper lse9nr45p1-editor_css',
  },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page lsea2jb6eip-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://i.miji.bid/2024/02/09/e034a18d4b512c83242a34f98c5e3f29.md.jpeg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>
                      Top-Class Table Tennis Teacher for Adults and Children in
                      Sydney
                    </p>
                  </span>
                </span>
              ),
              className: 'lse9qjtpcp7-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>About</p>
              </span>
            </span>
          ),
          className: 'lse9qp0ncji-editor_css',
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: 'FAQ',
              className: 'lse9qw44vin-editor_css',
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>Resource</p>
            </span>
          ),
          className: 'lse9r3577p6-editor_css',
        },
        childWrapper: {
          children: [
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>Facebook</p>
                </span>
              ),
              className: 'lse9r0ymc08-editor_css',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright lse9olbki3t-editor_css',
    children: (
      <span>
        <span>©2024 by MTTC</span>
      </span>
    ),
  },
};
